const cores = [
    "#1569C7",
    "#73BE52",
    "#FF0000",
    "#686A6C",
    "#D3D3D3",
    "#FFA500",
    "#808080",
    "#D4AF37",
    "#800080",
    "#008000",
    "#C984C0",
    "#A52A2A",
    "#000000",
    "#32741F",
    "#966F33",
    "#9089F2",
    "#4682B4",
    "#A9A9A9",
    "#48251C",
    "#98AFC7",
    "#728FCE",
    "#F8F0E3",
    "#FFCCCB",
    "#AF9B60",
    "#E0FFFF",
    "#C0C0C0",
    "#008080",
    "#C2DFFF",
    "#FFB6C1",
    "#FFFF00",
    "#0000FF",
    "#00FF00",
    "#8FAC90",
    "#C17AC4",
    "#7E587E",
    "#A5F7E1",
    "#000080",
    "#8C001A",
    "#4A4A6D",
    "#7F074B",
    "#C49BEC",
    "#FFFFE0",
    "#236A60",
    "#93516D",
    "#B3BBF0",
    "#90EE90",
    "#00FFFF",
    "#726E6D",
    "#7F5A58",
    "#8B0000",
    "#774C47",
    "#16E2F5",
    "#9D00FF",
    "#C48189",
    "#C04000",
    "#849966",
    "#5D8C96",
    "#85BB65",
    "#4B5320",
    "#006400",
    "#F535AA",
    "#15317E",
    "#BAB7C2",
    "#FED8B1",
    "#151B54",
    "#E05C5C",
    "#368BC1",
    "#1F45FC",
    "#91D085",
    "#FFD2C8",
    "#FF00FF",
    "#C8697E",
    "#0C090A",
    "#800000",
    "#1C070F",
    "#E56717",
    "#F6358A",
    "#77646F",
    "#BAA433",
    "#301A14",
    "#E2DABC",
    "#F0E2B6",
    "#EE9A4D",
    "#B4C2B3",
    "#F3FFED",
    "#BCC6CC",
    "#165F2C",
    "#398330",
    "#312B23",
    "#545F59",
    "#4F5230",
    "#BA7098",
    "#F5F5DC",
    "#838996",
    "#FBE7A1",
    "#FCDFFF",
    "#B2B7E3",
    "#0909FF",
    "#EBC5CA",
    "#FFFFFF",
]

export { cores }