<template>
  <v-row justify="center">
    <v-dialog width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="secundary" class="button-modal-secundary" style="min-height: 90px !important;"
               title="Toneladas Atendidas Hoje" v-bind="attrs" v-on="on" @click="() => click()">

          <div class="btn">
            <div class="btn-font">
              <v-img class="float-left mr-2 ml-2 img-button-modal-secundary" max-height="24" max-width="24" src="@/assets/images/icon/caminhao-de-entrega.png"/>
              <span class="mr-2 mb-0 ">Hoje</span>
            </div>
            <span class="font-weight-bold div-button-title"
              >{{ carregaInfoTons(dashboardAtendidos.dia_atual) }}
              <span style="font-size: 12px !important">ton</span>
            </span>
            <span class="div-button-subtitle">
              {{ carregaInfoQtd(dashboardAtendidos.dia_atual) }} caminhões
            </span>
          </div>
        </v-btn>
      </template>
      <dashboard-tab-detalhes ref="dadosProdutos" :dadosProdutos="this.dashboardAtendidosProdutosHoje.produtos"
                              :dadosDestinos="this.dashboardAtendidosDestinosHoje.destinos" :dadosEtapas="[]"/>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DashboardTabDetalhes from '@/components/dashboard/analytical/modais/DashboardTabDetalhes.vue'

export default {
  name: 'ModalToneladasAtendidasHoje',
  components: {
    DashboardTabDetalhes
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('patio', ['dashboardAtendidos']),
    ...mapState('patio', ['dashboardAtendidosProdutosHoje']),
    ...mapState('patio', ['dashboardAtendidosDestinosHoje']),
    ...mapState('auth', ['empresaAtual']),
    ...mapState('dashboard', ['params'])
  },
  methods: {
    ...mapActions('patio', [
      'getAtendidosProdutosHoje',
      'getAtendidosDestinosHoje'
    ]),

    async click() {
      await this.getAtendidosProdutosHoje({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      })

      await this.getAtendidosDestinosHoje({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      })
    },

    carregaInfoTons(valor) {
      if (valor === undefined) {
        return '-'
      } else {
        return parseFloat(valor.peso_total).toFixed(0)
      }
    },
    carregaInfoQtd(valor) {
      if (valor === undefined) {
        return '-'
      } else {
        return valor.quantidade_caminhoes
      }
    }
  },
};
</script>

