<template>
  <v-tabs v-model="tab">
    <v-tab href="#produtos">Produtos</v-tab>
    <v-tab href="#destinos">Destinos</v-tab>
    <v-tab href="#etapas" v-if="this.dadosEtapas.length > 0">Etapas</v-tab>
    <v-tabs-items v-model="tab">
      <v-tab-item value="produtos">
        <dashboard-produtos ref="produtos" :produtos="this.dadosProdutos"/>
      </v-tab-item>
      <v-tab-item value="destinos"> 
        <dashboard-destinos ref="destinos" :destinos="this.dadosDestinos"/>
      </v-tab-item>
      <v-tab-item value="etapas"> 
        <dashboard-etapas ref="etapas" :etapas="this.dadosEtapas"/>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import DashboardProdutos from '@/components/dashboard/analytical/modais/DashboardProdutos.vue'
import DashboardDestinos from '@/components/dashboard/analytical/modais/DashboardDestinos.vue'
import DashboardEtapas from '@/components/dashboard/analytical/modais/DashboardEtapas.vue'

export default {
  components: {
    DashboardProdutos,
    DashboardDestinos,
    DashboardEtapas
  },
  props: {
    dadosProdutos: {
      type: Array,
      required: true,
    },
    dadosDestinos: {
      type: Array,
      required: true,
    },
    dadosEtapas: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      tab: null
    }
  }
};
</script>

