<template>
  <v-row justify="center">
    <v-dialog width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="secundary" class="button-modal-secundary" style="min-height: 90px !important;"
               title="Na Triagem" v-bind="attrs" v-on="on" @click="() => click()">
          <div class="btn">
            <div class="btn-font">
              <v-img class="float-left mr-2 ml-2 img-button-modal-secundary" max-height="24" max-width="24" src="@/assets/images/icon/caminhao-de-entrega.png"/>
              <span class="mr-2 mb-0">Na Triagem</span>
            </div>
            <span class="font-weight-bold div-button-title"
              >{{ formatInfoTon(dashboardTriagem.triados_tons) }}
              <span style="font-size: 12px !important">ton</span>
            </span>
            <span class="div-button-subtitle">
              {{ formatInfoQty(dashboardTriagem.triados_caminhoes) }} caminhões

            </span>
          </div>
        </v-btn>
      </template>
      <dashboard-tab-detalhes :dadosProdutos="this.dashboardTriagemProdutos.produtos"
      :dadosDestinos="this.dashboardTriagemDestinos.destinos" :dadosEtapas="this.dashboardTriagemEtapas.etapas"/>

    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DashboardTabDetalhes from '@/components/dashboard/analytical/modais/DashboardTabDetalhes.vue'

export default {
  components: {
    DashboardTabDetalhes
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('patio', ['dashboardTriagem']),
    ...mapState('patio', ['dashboardTriagemProdutos']),
    ...mapState('patio', ['dashboardTriagemDestinos']),
    ...mapState('patio', ['dashboardTriagemEtapas']),
    ...mapState('auth', ['empresaAtual']),
    ...mapState('dashboard', ['params'])
  },
  methods: {
    ...mapActions('patio', [
      'getServicosExecutadosProdutos',
      'getServicosExecutadosDestinos',
      'getServicosExecutadosEtapas'
    ]),

    async click() {
      await this.getServicosExecutadosProdutos({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      }),

      await this.getServicosExecutadosDestinos({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      }),


      await this.getServicosExecutadosEtapas({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      })
    },

    formatInfoTon(value) {
      if (value === undefined || value === null) {
        return '0'
      } else {
        return parseFloat(value).toFixed(0)
      }
    },

    formatInfoQty(value) {
      if (value === undefined || value === null) {
        return '0'
      } else {
        return value
      }
    },

    formatInfoValue(value) {
      if (value === undefined || value === null) {
        return '-'
      } else {
        return value.toUpperCase()
      }
    }
  },
}
</script>
