<template>
  <v-sheet color="#00aa9e" rounded>
    <v-sheet outlined rounded>
      <v-container style="min-height: 200px; max-height: 500px; overflow-y: auto;">
        <template>
            <v-row class="destinos-line ml-0 mr-0 font-weight-bold destinos-title" align="center" justify="center">
              <v-col class="pt-0 pb-0 auto-adjust-title" cols="12" sm="12" md="6">
                  <span class="mr-3">Destinos</span>
              </v-col>
              <v-col class="pr-0" cols="6" sm="6" md="3" align="center">
                <span class="mr-3">Qtd. Cam.</span>
              </v-col>
              <v-col class="pl-0" cols="6" sm="6" md="3" align="center">
                <span class="mr-3">Tons</span>
              </v-col>
            </v-row>
        </template>
        <template>
            <v-row v-for="(destino, index) in this.destinos" :key="index"
                  class="destinos-line mt-1 ml-0 mr-0" align="center" justify="center">
              <v-col class="pt-0 pb-0 auto-adjust-title" cols="12" sm="12" md="6" style="display: ruby;">
                <div class="div-qty-destinos pl-3">
                  <span class="destinos-subtitle mb-2">{{ formatInfoValue(destino.destino).toUpperCase() }}</span>
                </div>
              </v-col>
              <v-col class="pr-0 pt-0 pb-0" cols="6" sm="6" md="3" align="center">
                  <span class="font-weight-bold destinos-subtitle mr-3 mt-2">{{ formatInfoQty(destino.count) }}</span>
              </v-col>
              <v-col class="pl-0 pt-0 pb-0" cols="6" sm="6" md="3" align="center">
                <span class="destinos-subtitle mt-2">{{ formatInfoTon(destino.peso) }}</span>
              </v-col>
            </v-row>
        </template>
      </v-container>
    </v-sheet>
  </v-sheet>
</template>

<script>

export default {
  props: {
    destinos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    }
  },
  methods: { 
    formatInfoTon(value) {
      if (value === undefined || value === null) {
        return '0'
      } else {
        return parseFloat(value).toFixed(0)
      }
    },

    formatInfoQty(value) {
      if (value === undefined || value === null) {
        return '0'
      } else {
        return value
      }
    },

    formatInfoValue(value) {
      if (value === undefined || value === null) {
        return '-'
      } else {
        return value
      }
    },
  }
};
</script>

<style>

.div-qty-destinos {
  padding-top: 15px !important;
}

.destinos-line {
  border-bottom: 2px solid #00aa9e !important;
}

.destinos-title {
  font-family: 'Poppins', sans-serif !important;
  font-size: 0.975rem;
  text-transform: uppercase;
}

.destinos-subtitle {
  font-family: 'Poppins', sans-serif !important;
  font-size: 0.875rem;
  line-height: 100% !important; 
  text-transform: uppercase;
  display: inline-block;
}

@media only screen and (max-width: 959px) {
  .auto-adjust-title {
    text-align: center;
  }
}

@media only screen and (min-width: 960px) {
  .auto-adjust-title {
    text-align: left;
  }
}

</style>
