<script>
import { Bar } from 'vue-chartjs'
import { mapState } from 'vuex'
import { cores } from '@/utils/cores'
export default {
  extends: Bar,
  props: {
    chartData: Object,
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
  },
  mounted() {
    this.render()
  },
  watch: {
    chartData() {
      this.render()
    },
  },
  methods: {
    render() {
      let datasets = []
      let color = 0
      let labels = []
      Object.entries(this.chartData).forEach((entrie) => {
        let value = entrie[1].datas
        labels = value.map(
          (dado) => new Date(dado.data).toLocaleDateString() || '-'
        )

        const previsto = {
          label: 'Previso',
          backgroundColor: cores[color],
          borderColor: cores[color],
          data: value.map((dado) => dado.quantidade_previsto || 0),
          fill: false,
        }
        datasets.push(previsto)
        color += 1
        const atendido = {
          label: 'Atendido',
          backgroundColor: cores[color],
          borderColor: cores[color],
          data: value.map((dado) => dado.quantidade_atendido || 0),
          fill: false,
        }
        datasets.push(atendido)
        color += 1
      })
      this.renderChart(
        {
          labels: [...labels],
          datasets: datasets,
        },
        {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          responsive: true,
          maintainAspectRatio: false,
        }
      )
    },
  },
}
</script>
