<template id="">
  <v-container fluid class="theme-dashboard">
    <v-row class="pb-0">
      <v-col sm="12" class="v-card v-sheet">
        <h2 class="text-center tittle-dashboard">Movimentação de Veículos</h2>
        <!--<<v-row style="display: none;">
          <v-col cols="12" sm="12" md="5">
            MovimentacaoVeiculos />
          </v-col>
          <v-col cols="12" sm="12" md="7">
            <MapaInterativoBrasil
              v-if="this.dashboardMapaMovimentacaoVeiculo.length > 0"
            />
          </v-col>
        </v-row>-->
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <BotoesDashboardAnalytical :loadBotoes="loadBotoes()" />
          </v-col>
        </v-row>
        <ModalViagemInfos />
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="12">
        <v-row>
          <v-col sm="12" md="12" class="v-card v-sheet">
            <ToneladasCarregadas :loadToneladas="loadToneladas()" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="12" class="v-card v-sheet">
        <TaxaProgramadoAtendido :loadTaxa="loadTaxa()" />
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.tittle-dashboard {
  font-family: 'Poppins', sans-serif !important;
  font-size: 22px !important;
  color: #00113a !important;
}
.theme-dashboard {
  /*background-color: #00AA9E3D;*/
  color: rgba(0, 0, 0, 0.87);
}
.div-border {
  background-color: whitesmoke;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
}
</style>

<script>
import { mapActions, mapState } from 'vuex'
import BotoesDashboardAnalytical from '@/components/dashboard/analytical/Botoes.vue'
import ToneladasCarregadas from '@/components/dashboard/analytical/ToneladasCarregadas.vue'
import ModalViagemInfos from '@/components/dashboard/analytical/modais/ModalViagemInfos'
//import MapaInterativoBrasil from '@/components/dashboard/analytical/widgets/MapaInterativoBrasil'
//import MovimentacaoVeiculos from '@/components/dashboard/analytical/MovimentacaoVeiculos'
import TaxaProgramadoAtendido from '@/components/dashboard/analytical/TaxaProgramadoAtendido.vue'
export default {
  data() {
    return {
      locations: [],
      page: {
        title: 'DashBoard',
      },
      breadcrumbs: [
        {
          text: 'DashBoard',
          disabled: true,
        },
      ],
    }
  },
  computed: {
    //...mapState('patio', ['dashboardMapaMovimentacaoVeiculo']),
    ...mapState('auth', ['empresaAtual']),
  },
  components: {
    BotoesDashboardAnalytical,
    ToneladasCarregadas,
    ModalViagemInfos,
    TaxaProgramadoAtendido,
    //MapaInterativoBrasil,
    //MovimentacaoVeiculos,
  },
  mounted() {
    this.locations = this.dashboardMapaMovimentacaoVeiculo
  },
  methods: {
    ...mapActions('patio', [
      'getAtendidos',
      'getEmTransito',
      'getServicosExecutados',
      'getAgendados',
      //'getMapaMovimentacaoVeiculo',
      'getCarregados',
      'getDescarregados',
      'getProgramadoAtendidoSemanal',
      'getTaxaChegadaSemanal',
    ]),

    async loadBotoes() {
      await this.getAgendados({
        ...this.params,
        proprietario: this.empresaAtual.public_id,

      })

      await this.getEmTransito({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      })

      await this.getServicosExecutados({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      })

      await this.getCarregados({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      })

      await this.getDescarregados({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      })
    },

    async loadToneladas() {
      await this.getAtendidos({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      })
    },

    async loadTaxa() {
      await this.getProgramadoAtendidoSemanal({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      })

      /*await this.getTaxaChegadaSemanal({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      })*/
    },
  }
}
</script>

<style></style>
