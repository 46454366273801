<template>
  <v-row justify="center">
    <v-dialog width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="secundary" class="button-modal-secundary" style="min-height: 90px !important;"
               title="Descarregados Até o Momento" v-bind="attrs" v-on="on" @click="() => click()">

          <div class="btn">
            <div class="btn-font">
              <v-img class="float-left mr-2 ml-2 img-button-modal-secundary" max-height="24" max-width="24" src="@/assets/images/icon/caminhao-de-entrega.png"/>
              <span class="mr-2 mb-0">Descarregados Até o Momento</span>
            </div>
            <span class="font-weight-bold div-button-title"
              >{{ formatInfoTon(dashboardDescarregados.tons_atual) }}
              <span style="font-size: 12px !important">ton</span>
            </span>
            <span class="div-button-subtitle">
              {{ formatInfoQty(dashboardDescarregados.caminhoes_atual) }} caminhões

            </span>
          </div>
        </v-btn>
      </template>
      <dashboard-tab-detalhes ref="dadosProdutos" :dadosProdutos="this.dashboardDescarregadosProdutosDiaAtual.produtos"
      :dadosDestinos="this.dashboardDescarregadosDestinosDiaAtual.destinos" :dadosEtapas="[]"/>

    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import DashboardTabDetalhes from '@/components/dashboard/analytical/modais/DashboardTabDetalhes.vue'

export default {
  components: {
    DashboardTabDetalhes
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('patio', ['dashboardDescarregados']),
    ...mapState('patio', ['dashboardDescarregadosProdutosDiaAtual']),
    ...mapState('patio', ['dashboardDescarregadosDestinosDiaAtual']),
    ...mapState('auth', ['empresaAtual']),
    ...mapState('dashboard', ['params'])
  },
  methods: {
    ...mapActions('patio', [
      'getDescarregadosProdutosDiaAtual',
      'getDescarregadosDestinosDiaAtual'
    ]),

    async click() {
      await this.getDescarregadosProdutosDiaAtual({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      }),

      await this.getDescarregadosDestinosDiaAtual({
        ...this.params,
        proprietario: this.empresaAtual.public_id
      })
    },

    formatInfoTon(value) {
      if (value === undefined || value === null) {
        return '0'
      } else {
        return parseFloat(value).toFixed(0)
      }
    },

    formatInfoQty(value) {
      if (value === undefined || value === null) {
        return '0'
      } else {
        return value
      }
    }
  }

}
</script>
