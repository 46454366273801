<template>
  <v-container fluid>
    <v-row align="center mt-1">
      <v-col cols="12" sm="12" md="4" class="mt-1 my-0 py-0" style="height: 90px;">
        <ModalAgendadosInfo class="mr-1 ml-1"/>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="mt-1 my-0 py-0" style="height: 90px;">
        <ModalCarregadosHojeInfo class="mr-1 ml-1"/>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="mt-1 my-0 py-0" style="height: 90px;">
        <ModalCarregadosOntemInfo class="mr-1 ml-1"/>
      </v-col>
    </v-row>
    <v-row align="center mt-5">
      <v-col cols="12" sm="12" md="4" class="mt-1 my-0 py-0" style="height: 90px;">
        <ModalNaTriagemInfo class="mr-1 ml-1"/>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="mt-1 my-0 py-0" style="height: 90px;">
        <ModalDescarregadosHojeInfo class="mr-1 ml-1"/>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="mt-1 my-0 py-0" style="height: 90px;">
        <ModalDescarregadosOntemInfo class="mr-1 ml-1"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="scss">
.btn {
  display: grid;
  flex-wrap: wrap;
}

.div-button {
  height: 105px !important;
  padding-top: 15px !important;
}

.div-button-title {
  font-family: 'Poppins', sans-serif !important;
  font-size: 22px !important;
  color: #00113a !important;
}

.div-button-subtitle {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  line-height: 110% !important;
  color: #00113a !important;
}

.btn-font {
  display: inline-flex;
  align-items: center;
  font-size: 90%;
}

.button-modal-secundary {
  color: rgb(0, 17, 58) !important;
  height: 80px !important;
  align-items: baseline !important;
  padding: 10px !important;
  text-transform: initial !important;
  letter-spacing: 0.1px !important;
  width: 100% !important;
  font-size: 13px !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.5);
}

.img-button-modal-secundary {
  color: rgb(0, 17, 58) !important;
}
</style>

<script>
import { mapActions } from 'vuex';
import ModalCarregadosHojeInfo from '@/components/dashboard/analytical/modais/ModalCarregadosHojeInfo';
import ModalCarregadosOntemInfo from '@/components/dashboard/analytical/modais/ModalCarregadosOntemInfo';
import ModalDescarregadosHojeInfo from '@/components/dashboard/analytical/modais/ModalDescarregadosHojeInfo';
import ModalDescarregadosOntemInfo from '@/components/dashboard/analytical/modais/ModalDescarregadosOntemInfo';
import ModalNaTriagemInfo from '@/components/dashboard/analytical/modais/ModalNaTriagemInfo';
import ModalAgendadosInfo from '@/components/dashboard/analytical/modais/ModalAgendadosInfo';

export default {
  name: 'BotoesDashboardAnalytical',
  data() {
    return {
    }
  },
  computed: {

  },
  async created() {

  },
  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),

    openModalNaTriagem() {
      var item = '';
      this.$root.$emit('modal_viagem_infos:show_modal', item);
    },

  },
  components: {
    ModalNaTriagemInfo,
    ModalAgendadosInfo,
    ModalCarregadosHojeInfo,
    ModalCarregadosOntemInfo,
    ModalDescarregadosHojeInfo,
    ModalDescarregadosOntemInfo
  },
  props: {
    loadBotoes: {
      type: Promise,
      required: true,
    }
  }
}
</script>
