<template id="TaxaProgramadoAtendido">
  <v-container class="pt-2"
    v-if="Object.entries(dashboardProgramadoAtendidoSemanal).length > 0">
    <v-row>
      <v-col>
        <h2 class="text-center tittle-dashboard">
          Relação Previsto x Atendido
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider class="m-5"></v-divider>
      </v-col>
    </v-row>
    <v-row class="div-border">
      <v-col>
        <h4 class="text-center">Visão geral</h4>
      </v-col>
    </v-row>
    <v-row class="div-border">
      <v-col>
        <LinesProgramadoAtendido
          :chartData="Object.entries(dashboardProgramadoAtendidoSemanal)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider class="m-5"></v-divider>
      </v-col>
    </v-row>
    <v-row class="div-border">
      <v-col>
        <h4 class="text-center">Visão por terminal</h4>
      </v-col>
    </v-row>
    <v-row class="div-border">
      <v-col
        cols="6"
        v-for="entrie in Object.entries(dashboardProgramadoAtendidoSemanal)"
        :key="entrie[0]"
      >
        <h6 class="text-center">{{ entrie[1].terminal }}</h6>
        <BarProgramadoAtendido
          :chartData="Object.fromEntries([entrie])"
        ></BarProgramadoAtendido>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LinesProgramadoAtendido from '@/components/Charts/LinesProgramadoAtendido'
import BarProgramadoAtendido from '@/components/Charts/BarProgramadoAtendido'
import { mapState } from 'vuex'

export default {
  name: 'TaxaProgramadoAtendido',
  components: {
    LinesProgramadoAtendido,
    BarProgramadoAtendido,
  },
  data() {
    return {}
  },
  created() {
    this.dashboardAtendidoChegadaSemanal
  },
  computed: {
    ...mapState('patio', ['dashboardProgramadoAtendidoSemanal']),
    ...mapState('auth', ['empresaAtual']),
  },
  mounted() {
    if(this.dashboardAtendidoChegadaSemanal !== undefined || this.dashboardAtendidoChegadaSemanal !== null)
    {
      this.$nextTick(function () {
        this.dashboardAtendidoChegadaSemanal
      })
    }
  },
  props: {
    loadTaxa: {
      type: Promise,
      required: true,
    }
  }
}
</script>
