<template id="ModalViagemInfos">
  <div class="">
    <v-dialog v-model="showModalDialog" max-width="600px" @click:outside="closeDialog">
      <v-sheet outlined color="#00aa9e" rounded>
        <v-sheet outlined rounded>
        <v-container>
          <h2 class="tittle-dashboard text-center">Previsão de Chegada</h2>
          <v-row>
            <v-col class="pb-0" align="center" justify="center">
              <v-text-field disabled label="De"></v-text-field>
            </v-col>
            <v-col class="pb-0" align="center" justify="center">
              <v-text-field disabled label="Para" v-model="uf_seleted.name">}</v-text-field>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="col-sm-3 pr-0" align="right">
              <v-img max-height="110" max-width="110" src="@/assets/images/icon/relogio.png"/>
            </v-col>
            <v-col class="pl-0">
              <h3 class="viagem-title font-weight-bold">4 Veículos</h3>
              <span class="viagem-subtitle font-weight-bold">Média de Tempo de Chegada</span><br>
              <span class="viagem-subtitle">19,4 horas</span><br>
            </v-col>
          </v-row>
          <v-row class="mt-0" align="center">
            <v-col class="pt-0" align="center">
              <h2 class="tittle-dashboard text-center">Placas em Trânsito</h2>
              <v-list id="ListPlacas">
                <v-list-item v-for="(placa, i) in placas" :key="i" style="color:#00113a !important;min-height: 30px !important;">
                  <v-list-item-icon class="mr-2 mb-0 mt-1">
                    <v-icon light right>mdi-checkbox-marked-circle</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="pt-0 pb-0">
                    <v-list-item-title style="text-align: center" v-text="placa.placa"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data(){
    return {
      placas: [
        { placa: 'ABC-1234' },
        { placa: 'AJU-7865' },
        { placa: 'AHY-1A23' },
        { placa: 'ZZW-9577' },
        { placa: 'ABC-1234' },
        { placa: 'AJU-7865' },
        { placa: 'AHY-1A23' },
        { placa: 'ZZW-9577' },
        { placa: 'QYF-2020' },
      ],
      showModalDialog: false,
      uf_seleted: {}
    }
  },
  mounted(){
    this.$root.$on('modal_viagem_infos:show_modal', (item)=>{
      this.showModalDialog = true;
      this.uf_seleted = item;

      //Axax para buscar dados do modal
      /*
      this.$axios('url_buscar_viagem')
      .then((response)=>{

      })
      */
    });
  },
  methods: {
    closeDialog(){
      this.$root.$emit('mapa-estado:disabled');
    }
  }
}
</script>

<style>
#ListPlacas{
  height:200px;/* or any height you want */
  overflow-y:auto;
  width: 200px;
}

.viagem-title {
  font-family: 'Poppins', sans-serif !important;
  font-size: 22px !important;
  color: #f9a72b !important;
  text-transform: uppercase !important;
}

.viagem-subtitle {
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px !important;
  color:#00113a !important;
  font-style: italic !important;
}
</style>
