<template id="ToneladasCarregadas">
  <v-container class="pt-1">
    <h2 class="text-center tittle-dashboard pb-2">Toneladas Atendidas</h2>
    <v-row class="mt-1" align="center" justify="center">
      <v-col cols="12" sm="12" md="4" class="mt-1 my-0 py-0" style="height: 90px;">
        <ModalToneladasAtendidasHoje class="mr-1 ml-1"/>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="mt-1 my-0 py-0" style="height: 90px;">
        <ModalToneladasAtendidasOntem class="mr-1 ml-1"/>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="mt-1 my-0 py-0" style="height: 90px;">
        <ModalToneladasAtendidasNaSemana class="mr-1 ml-1"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import ModalToneladasAtendidasHoje from '@/components/dashboard/analytical/modais/ModalToneladasAtendidasHoje';
import ModalToneladasAtendidasOntem from '@/components/dashboard/analytical/modais/ModalToneladasAtendidasOntem';
import ModalToneladasAtendidasNaSemana from '@/components/dashboard/analytical/modais/ModalToneladasAtendidasNaSemana';

export default {
  name: '',
  components: {
    ModalToneladasAtendidasHoje,
    ModalToneladasAtendidasOntem,
    ModalToneladasAtendidasNaSemana
  },
  data() {
    return {
      diaAtual: 0,
      diaAnterior: {},
      semanal: {},
    }
  },
  computed: {
    ...mapState('patio', ['dashboardAtendidos']),
    ...mapState('dashboard', ['params']),
    ...mapState('auth', ['empresaAtual']),
  },
  methods: {

  },
  props: {
    loadToneladas: {
      type: Promise,
      required: true,
    }
  }
}
</script>

<style>
.div-qty-toneladas {
  padding-top: 8px !important;
}

.toneladas-title {
  font-family: 'Poppins', sans-serif !important;
  font-size: 26px !important;
  color: #00113a !important;
}

.toneladas-subtitle {
  font-family: 'Poppins', sans-serif !important;
  font-size: 17px !important;
  line-height: 100% !important;
  color: #00113a !important;
}
</style>
